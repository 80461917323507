<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";

import {mapState, mapGetters} from "vuex";

import simplebar from "simplebar-vue";

import {with_acrf_token} from "@/methods";


export default {
  page: {
    title: "Support",
    meta: [{ name: "description", content: appConfig.description }]
  },
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId'
    ])
  },
  components: {
    Layout,
    SupportStatus: () => import('@/components/lazy/support/SupportStatus'),
    SupportTickets: () => import('@/components/lazy/support/SupportTickets')
  },
  methods: {

  },
  created() {

  },
  data() {
    return {

    }
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-flex align-items-center justify-content-between">
      <h4 class="mb-0 font-size-18">
        Support
        <router-link to="/account" class="small ml-2">
          <i class="fas fa-backspace"></i> Go back
        </router-link>
      </h4>
      <div class="page-title-right bv-d-xs-down-none"><ol class="breadcrumb m-0">
        <li class="breadcrumb-item">
          <router-link to="/">
            CFTools Cloud
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span aria-current="location">
            Support
          </span>
        </li>
      </ol>
      </div>
    </div>
    <div class="row d-flex">
      <SupportTickets></SupportTickets>
      <div class="col-lg-4 col-sm-12">
        <SupportStatus></SupportStatus>
      </div>
    </div>
  </Layout>
</template>